@import '~styles/colors';

.container {
  position: relative;

  .veil {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color3;
  }
}
