$black: #000000;
$charcoal: #252529;
$white: #ffffff;
$primaryBlue: #1c97f5;
$lightGrey: #f5f5f7;

$color1: $white;
$color2: $black;
$color3: $charcoal;
$color4: $primaryBlue;
$color5: $lightGrey;
