@import '~styles/colors';

$headerHeight: 60px;

.container {
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: $headerHeight;
    padding-left: 30px;
    box-shadow: 0 2px 20px 0 rgba($color2, 0.05), inset 0 -1px 0 0 rgba($color2, 0.1);
    background-color: $color1;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.7px;
    color: #333333;
  }

  .content {
    height: calc(100% - #{$headerHeight});
    padding: 30px;
    background-color: $color5;
  }

  .welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba($color2, 0.05);
    background-color: $color1;

    .title {
      font-size: 40px;
      font-weight: 600;
      line-height: 0.7;
      color: #23282e;
    }

    .subtitle {
      max-width: 472px;
      margin-top: 21px;
      font-size: 20px;
      line-height: 1.3;
      text-align: center;
      color: rgba(35, 40, 46, 0.6);
    }

    .image {
      width: 277px;
      height: 237px;
      margin-top: 20px;
    }
  }
}
