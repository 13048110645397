@import '~styles/colors';

.container {
  animation: spinner 1s linear infinite;

  img {
    width: 60px;
  }
}

@keyframes spinner {
  to {
    transform: rotate(-360deg);
  }
}
