.container {
  display: flex;
  height: 100vh;

  .navigation {
    width: 250px;
    overflow: auto;

    &.collapsed {
      width: 60px;
      overflow: hidden;
    }
  }

  .project {
    flex: 1;
  }
}
